import "./ProcessNew.css"

import process1 from "../../../Assets/Process/01.mp4"
import process2 from "../../../Assets/Process/02.mp4"
import process3 from "../../../Assets/Process/03.mp4"
import process4 from "../../../Assets/Process/04.mp4"
import process5 from "../../../Assets/Process/05.mp4"
import process6 from "../../../Assets/Process/06.mp4"
import process7 from "../../../Assets/Process/07.mp4"
import process8 from "../../../Assets/Process/08.mp4"
import process9 from "../../../Assets/Process/09.mp4"
import process10 from "../../../Assets/Process/10.mp4"
import process11 from "../../../Assets/Process/11.mp4"
import process12 from "../../../Assets/Process/12.mp4"

const process = [
    {
        id: 1,
        title: "Concept & Goal",
        description: "Define the video's purpose-what story are you trying to tell, or what message would you like to be conveyed?",
        image: process2,
    },
    {
        id: 2,
        title: "Script writing",
        description: "Write a script with dialogues, including scene descriptions and necessary actions. Keep it concise and note your objective.",
        image: process1,
    },
    {
        id: 3,
        title: "Storyboarding",
        description: "Draw out scenes to visualize your video. Storyboarding will help you plan camera angles, shots, and transitions.",
        image: process3,
    },
    {
        id: 4,
        title: "Equipments",
        description: "Decide on the needed equipment (camera, lighting, microphones) and who will be handling filming, acting, or directing.",
        image: process4,
    },
    {
        id: 5,
        title: "Setup",
        description: "Prepare the location, adjust lighting, and ensure that your set matches the storyboard.",
        image: process5,
    },
    {
        id: 6,
        title: "Models & Location Permits",
        description: "From securing professional models to obtaining location permits, we make sure every detail is covered so your production runs smoothly and legally.",
        image: process6,
    },
    ,
    {
        id: 7,
        title: "Filming",
        description: "Record scenes as planned; if possible, capture multiple takes. Make sure to emphasize smooth shots, appropriate framing, and clear audio.",
        image: process7,
    },
    ,
    {
        id: 8,
        title: "B-Roll",
        description: "Additional filming of things that could be used in cutaways and transitions while editing, which may be scenery, close-ups, or action shots.",
        image: process8,
    },
    ,
    {
        id: 9,
        title: "Editing",
        description: "Sew your video together in any video editing software. Adjust the pacing, insert transitions.",
        image: process9,
    },
    ,
    {
        id: 10,
        title: "Sound Design",
        description: "Sync audio, add background music, and add sound effects. Make sure voices are clear and balanced with other audio elements.",
        image: process10,
    },
    ,
    {
        id: 11,
        title: "Visual Effects",
        description: "Add titles, graphics, animations, or color grading to make your video look better.",
        image: process11,
    },
    ,
    {
        id: 12,
        title: "Review",
        description: "Go through your final cut repeatedly to spot errors and fix them.",
        image: process12,
    },
]

export default function ProcessNew() {
    return (
        <section id="process">
            <div className="process-container container1">
                <div className="title">
                    <h2>Our Process</h2>
                </div>
                <div className="process-grid">
                    {process.map((ele, i) => {
                        return (
                            <div className="process-grid-card">
                                <span className="si-no">{ele.id}.</span>
                                <div className="process-image">
                                    <video src={ele.image} alt={ele.title} autoPlay loop muted />
                                </div>
                                <div className="process-content">
                                        <h1>{ele.title}</h1>
                                        <p>{ele.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}